import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './App';
import { Header, Footer } from './containers';
import { Navbar } from './components';
import { Abstracts, AbstractsDetails } from './multisite';
import { AbstractsProvider } from './AbstractsContext';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <AbstractsProvider>
        <BrowserRouter>
            <div className="App">
                <span className="anchor-about" id="giswnauce_about"></span>
                <Header />
                <Navbar />
                <Routes>
                    <Route path="" element={<React.StrictMode><App /></React.StrictMode>} />
                    <Route path="giswnauce-abstracts" element={<React.StrictMode><Abstracts /></React.StrictMode>} />
                    <Route path="/giswnauce-abstracts/:id" element={<React.StrictMode><AbstractsDetails /></React.StrictMode>} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    </AbstractsProvider>
);
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AbstractsContext from '../../AbstractsContext';
import { Spinner } from '../../components';

import './abstractsdetails.css';

const Abstractsdetails = () => {
  const handleScroll = () => {
    const position = window.pageYOffset;
    let elem = document.getElementsByClassName("sticky-navbar")[0];
    let rect = elem.getBoundingClientRect().top;
    if(position > (rect + window.scrollY)) {
      document.getElementsByClassName("giswnauce__navbar")[0].classList.add("scroll-nav");
      document.getElementsByClassName("giswnauce__abstracts-details")[0].classList.add("scroll-margin");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.add("scroll-committees-dropdown-fixed");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.remove("scroll-committees-dropdown-absolute");
      if(document.getElementsByClassName("giswnauce__navbar-menu_cont")[0] !== undefined){
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.add("scroll-dropdown-fixed");
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.remove("scroll-dropdown-absolute");
      }
    }
    else {
      document.getElementsByClassName("giswnauce__navbar")[0].classList.remove("scroll-nav");
      document.getElementsByClassName("giswnauce__abstracts-details")[0].classList.remove("scroll-margin");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.remove("scroll-committees-dropdown-fixed");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.add("scroll-committees-dropdown-absolute");
      if(document.getElementsByClassName("giswnauce__navbar-menu_cont")[0] !== undefined){
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.remove("scroll-dropdown-fixed");
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.add("scroll-dropdown-absolute");
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { id } = useParams();
  const tiles = useContext(AbstractsContext);

  const [loading, setLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    if (showSpinner) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [showSpinner]);

  useEffect(() => {
    if (!tiles || tiles.length < 54) {
      setLoading(true);
      setShowSpinner(true);
    }
  }, [tiles]);
  
  useEffect(() => {
    if (tiles && tiles.length >= 54) {
      setLoading(false);
      setShowSpinner(false);
    }
  }, [tiles]);

  if (showSpinner || loading) {
    return <div className="giswnauce__abstracts-details"><Spinner /></div>;
  }

  const abstract = tiles.find(abstract => abstract.id === id);

  if (!abstract) {
    return <p>File not found</p>;
  }

  return (
    <div className="giswnauce__abstracts-details">
      <div className="abstract-details-container">
        {!(abstract.startTime === "00:00" && abstract.endTime === "23:59") && (
          <h1 className="details_time">{abstract.startTime}-{abstract.endTime}</h1>
        )}
        <h2 className="details_title">{abstract.titlepl}</h2>
        <div className="details_people">
          <h3 className="details_person">
            {[...Array(8)]
              .map((_, index) => abstract[`name${index + 1}`])
              .filter(Boolean)
              .map((personName, index, array) => {
                const [fullName, additionalInfo] = personName.split(/,(.+)/);
              
                return (
                  <React.Fragment key={index}>
                    {fullName + ' '}
                    {additionalInfo && <sup>{additionalInfo.trim()}</sup>}
                    {index < array.length - 1 && ', '}
                  </React.Fragment>
                )
            })}
          </h3>
          <h3 className="details_affiliation">
            {[...Array(8)].map((_, index) => {
            const personAffiliation = abstract[`affiliation${index + 1}`];
              return personAffiliation ? (
                <div key={index} style={{ textIndent: '-0.5rem', paddingLeft: '1rem' }}>
                  <sup>{index + 1}</sup> {personAffiliation}
                </div>
              ) : null;
            })}
          </h3>
        </div>
        <h3 style={{ textIndent: '-0.5rem', paddingLeft: '1rem' }} className="details_email"><sup>*</sup> {abstract.email}</h3>
        <p className="details_abstract">
          <b>Abstrakt</b><br />
          {abstract.abstractpl}
        </p>
        <h4 className="details_key"><b style={{ color: 'var(--color-black)' }}>Słowa kluczowe:</b> {abstract.keypl}</h4>
        {(abstract.funding) && (
          <h5 className="details_funding"><i>{abstract.funding}</i></h5>
        )}
        <span className="details_separator" />
        <h2 className="details_title">{abstract.titleen}</h2>
        <p className="details_abstract">
          <b>Abstract</b><br />
          {abstract.abstracten}
        </p>
        <h4 className="details_key"><b style={{ color: 'var(--color-black)' }}>Keywords:</b> {abstract.keyen}</h4>
      </div>
    </div>
  )
}

export default Abstractsdetails
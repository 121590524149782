import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/free-solid-svg-icons';

import './agenda.css';

const Agenda = () => {
  return (
    <div className="giswnauce__agenda">
        <div className="giswnauce__agenda-container">
            <span className="anchor-agenda" id="giswnauce_agenda"></span>
            <div className="agenda-container_header">
                <p><FontAwesomeIcon icon={faListUl} /></p><h1>Program Konferencji</h1>
            </div>
            <div className="agenda-container_subheader">
                <h2>26 września 2024 (Dzień 1)</h2>
            </div>
            <div className="agenda-container_description first_table">
                <table>
                    <thead>
                        <tr>
                            <th>Godziny</th>
                            <th>Element konferencji</th>
                            <th>Czas trwania</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>09:00 - 10:00</td>
                            <td>Rejestracja uczestników</td>
                            <td>1h</td>
                        </tr>
                        <tr>
                            <td>10:00 - 11:00</td>
                            <td>Uroczyste rozpoczęcie i&nbsp;wykład inauguracyjny</td>
                            <td>1h</td>
                        </tr>
                        <tr>
                            <td>11:00 - 11:20</td>
                            <td>Wykład Partnera głównego - ESRI Polska</td>
                            <td>20min</td>
                        </tr>
                        <tr>
                            <td>11:20 - 12:00</td>
                            <td>Przerwa kawowa / Sesja posterów</td>
                            <td>40min</td>
                        </tr>
                        <tr>
                            <td>12:00 - 14:00</td>
                            <td>Sesja referatów I - "Środowisko"</td>
                            <td>2h</td>
                        </tr>
                        <tr>
                            <td>14:15 - 15:00</td>
                            <td>Przerwa obiadowa</td>
                            <td>45min</td>
                        </tr>
                        <tr>
                            <td>15:00 - 17:00</td>
                            <td>Sesja referatów II - Planowanie Przestrzenne, Miasto</td>
                            <td>2h</td>
                        </tr>
                        <tr>
                            <th colSpan="3">Po konferencji</th>
                        </tr>
                        <tr>
                            <td>19:00 - 22:00</td>
                            <td>Uroczysta kolacja</td>
                            <td>3h</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="agenda-container_subheader">
                <h2>27 września 2024 (Dzień 2)</h2>
            </div>
            <div className="agenda-container_description second_table">
            <table>
                    <thead>
                        <tr>
                            <th>Godziny</th>
                            <th>Element konferencji</th>
                            <th>Czas trwania</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>10:30 - 12:00<br />Sala 1</td>
                            <td>Sesja referatów IIIa - Technologia i&nbsp;Teledetekcja</td>
                            <td>1h 30min</td>
                        </tr>
                        <tr>
                            <td>10:30 - 12:00<br />Sala 2</td>
                            <td>Sesja referatów IIIb - Gospodarka Wodna, Lasy, Usługi Ekosystemowe</td>
                            <td>1h 30min</td>
                        </tr>
                        <tr>
                            <td>12:30 - 13:30</td>
                            <td>Przerwa kawowa / Sesja posterów</td>
                            <td>1h</td>
                        </tr>
                        <tr>
                            <td>13:30 - 14:30</td>
                            <td>Debata GIS w Nauce</td>
                            <td>1h</td>
                        </tr>
                        <tr>
                            <td>14:30 - 15:30</td>
                            <td>Przerwa obiadowa</td>
                            <td>1h</td>
                        </tr>
                        <tr>
                            <td>15:30 - 17:00</td>
                            <td>Warsztaty</td>
                            <td>1h 30min</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> 
    </div>
  )
}

export default Agenda